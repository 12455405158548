.contact
{
    background-color: #43453f;
    border-radius: 20px;
    position: -webkit-sticky;
    position: sticky;

    &__item
    {
        padding: 35px;
        &__title
        {
            h5
            {
                color: #bca21d;
                font-size: 30px;
                font-weight: 400;
                margin: 0;
            }
        }
        form
        {
            label
            {
                color: #fff;
                font-size: 14px;
                font-weight: 400;
                margin: 25px 0 5px;
            }

            input
            {
                width: 100%;
                border: none;
                border-radius: 5px;
                font-size: 12px;
                line-height: 40px;
            }
            button
            {
                border: none;
                background-color: #bca21d;
                color: #fff;
                display: inline-block;
                padding: 0 29px;
                font-size: 16px;
                font-weight: 600;
                line-height: 46px;
                border-radius: 5px;
                margin: 10px 0;

            }
            textarea
            {
                border-radius: 5px;
                width: 100%;
            }
        }
    }
}