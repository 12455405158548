html {
    scroll-behavior: smooth;
  }
body
{
    background-color:#fff;
    direction: ltr;
    text-align: left;
    font-family: sans;
}
*
{
    box-sizing: border-box;
}
::-moz-selection { /* Code for Firefox */
    color: #fff;
    background: #bca21d;
  }
  
  ::selection {
    color: #fff;
    background: #bca21d;
  }
.container
{
    max-width: 1496px;
}
@media (max-width : 1200px)
{
    .container
    {
        max-width: 1140px;
    }
}
@media (max-width : 991px)
{
    .container
    {
        max-width: 960px; 
    }
    
}