.contactus
{
    background-image:url(../img/gold-blend-1.png) ;
    background-position: center;
    background-repeat: no-repeat;
    .container
    {
        max-width: 1100px;
    }
    &__item
    {
        padding: 50px 0;
        &__form
        {
            margin-bottom: 50px;
            &__title
            {
                background-color: #000;
                padding: 20px;
                border-radius: 15px 15px 0px 0px;
                border-bottom: 10px solid  #bca21d;
                h5
                {
                    margin: 0;
                    color: #fff;
                    font-size: 30px;
                }
            }
            &__list
            {
                background-color: #9eacaa;
                border-radius: 0 0 15px 15px;
                padding: 20px;
                form
                {
                    label
                    {
                        font-size: 12px;
                        color: rgb(75, 73, 73);
                        margin-top: 20px;
                        margin-bottom: 10px;
                    }
                    input
                    {
                        width: 100%;
                        line-height: 30px;
                        padding: 0 10px;
                        border: none;
                        font-size: 13px;
                        font-weight: 400;
                        &:focus
                        {
                            box-shadow: none;
                            border: none;
                        }
                    }
                    button
                    {
                        margin: 30px 0;
                        display: inline-block;
                        line-height: 30px;
                        padding: 0 20px;
                        background-color: #bca21d;
                        color: #fff;
                        font-size: 12px;
                        font-weight: 600;
                        border: none;
                        border-radius: 5px;
    
    
                    }
                }
                &__footer
                {
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    img
                    {
                        width: 25px;
                        height: 25px;
                        margin-right: 10px;
                    }
                    span
                    {
                        color: #999;
                        font-size: 13px;
                        font-weight: 400;
                        a
                        {
                            color: #00a4bd;
                        }
                    }
                }
            }
        }
        #map 
        { 

            height: 300px; 
        }
        &__text
        {
            margin: 30px 0;
            &__title
            {  
                h4
                {
                    font-size: 33px;
                    color: #bca21d;
                    font-weight: 400;
                }

            }
            &__address
            {
                margin: 30px 0;
                max-width: 300px;
                p
                {
                    line-height: 30px;
                }
            }
            &__phone
            {
                display: flex;
                align-items:center;
                img
                {
                    width: 61px;
                    height: 61px;
                    margin-right: 30px;
                }
                ul
                {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }
            }

        }
    }
}
.contactus
{

    &__bottom
    {
        .container
        {
            max-width: 1100px;
        }
        padding: 100px 0;
        &__title
        {
            text-align: center;
            h5
            {
                color: #bca21d;
                font-size: 25px;
                font-weight: 300;
            }
        }
        &__menu
        {
            margin: 50px 0;
            ul
            {
                list-style: none;
                padding: 0;
                margin: 0;
                margin-bottom: 50px;
                li
                {
                    display: block;
                    line-height: 50px;
                    span
                    {

                    }
                }
            }
        }
    }
}