.header
{
    background-color: #000;
    &.fixed
    {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999999;
    }
    &__item
    {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__logo
        {
            padding: 30px 0;
            width: 196px;
            img
            {
                width: 100%;
                height: 100%;
            }
        }
        &__menu
        {
            >ul
            {
                display: flex;
                padding: 0;
                margin: 0;
                list-style: none;
                position: relative;
                line-height: 120px;
                li
                {
                    margin-right: 40px;
                    
                    &:last-child
                    {
                        margin-right: 0;
                    }
                    a
                    {
                        color: #fff;
                        font-size: 13px;
                        font-weight: 600;
                        text-decoration: none;
                        transition: 0.2s;
                        &:hover
                        {
                            color: #bca21d;
                        }
                    }
                    ul
                    {
                        visibility: hidden;
                        opacity: 0;
                        position: absolute;
                        padding: 0;
                        margin: 0;
                        bottom: -100px;
                        right: 0;
                        list-style: none;
                        color: #666;
                        width: 100%;
                        transition: 0.4s;
                        z-index: 1111;
                        
                        li
                        {
                            .search
                            {
                                display: flex;
                                background-color: #252622;
                                padding: 20px;
                                width: 100%;
                                form
                                {
                                    background: #fff;
                                    width: 100%;
                                    line-height: 40px;
                                    position: relative;
                                    .form-group
                                    {
                                        input
                                        {
                                            border: none;
                                            background: transparent;
                                            padding: 0 20px;
                                            width: 100%;
                                            line-height: 40px;
                                            &:focus
                                            {
                                                border: none;
                                                background-color: transparent;
                                                box-shadow: none;
                                            }
                                        }
                                    }


                                    button
                                    {
                                        border: none;
                                        background: transparent;
                                        line-height: 40px;
                                        position: absolute;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        right: 15px;
                                        padding: 0;
                                        font-size: 14px;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__mobile
    {
        display: none;
        &__list
        {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__logo
            {
                padding: 30px 0;
                width: 196px;
                img
                {
                    width: 100%;
                    height: 100%;
                }
            }
            &__icon
            {
                i
                {
                    color: #fff;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

        }
        &__menu
        {
            display: none;
            background-color: #000;
            >ul
            {
                list-style: none;
                padding: 0;
                margin: 0;
                display: block;
                position: relative;

                li
                {
                    display: block;
                    line-height: 40px;
                    a
                    {
                        display: block;
                        align-items: center;
                        color: #fff;
                        font-size: 14px;
                        text-decoration: none;
                    }
                    ul
                    {
                        visibility: hidden;
                        opacity: 0;
                        position: absolute;
                        padding: 0;
                        margin: 0;
                        bottom: -100px;
                        right: 0;
                        list-style: none;
                        color: #666;
                        width: 100%;
                        transition: 0.4s;
                        z-index: 1111;
                    
                        li
                        {
                            .search
                            {
                                display: flex;
                                background-color: #252622;
                                padding: 20px;
                                width: 100%;
                                form
                                {
                                    background: #fff;
                                    width: 100%;
                                    line-height: 40px;
                                    position: relative;
                                    .form-group
                                    {
                                        input
                                        {
                                            border: none;
                                            background: transparent;
                                            padding: 0 20px;
                                            width: 100%;
                                            line-height: 40px;
                                            &:focus
                                            {
                                                border: none;
                                                background-color: transparent;
                                                box-shadow: none;
                                            }
                                        }
                                    }


                                    button
                                    {
                                        border: none;
                                        background: transparent;
                                        line-height: 40px;
                                        position: absolute;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        right: 15px;
                                        padding: 0;
                                        font-size: 14px;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }


                }
            }
        }
    }

}
@media (max-width : 991px)
{
    .header
    {
        &__item
        {
            display: none;
        }
        &__mobile
        {
            display: block;
        }
    }
}