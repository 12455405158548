// libs
    @import '../../../node_modules/bootstrap/dist/css/bootstrap.min';
    @import '../../../node_modules/aos/dist/aos';
    @import '../../../node_modules/leaflet/dist/leaflet';


//fonts
    @import '../fonts/fontawesome-free-6.0.0-web/css/all.css';
    @import 'part/font';

//parts
@import 'part/body';
@import 'part/header';
@import 'part/slider';
@import 'part/services';
@import 'part/contact';
@import 'part/footer';

//page
@import 'part/contactus';
