.footer
{
    background-color: #000;
    .container
    {
        max-width: 1496px;
    }
    &__item
    {

        padding: 30px 0;

        &__menu
        {
            &__title
            {
                h6
                {
                    font-size: 20px;
                    color: #8e9c9c;
                }
            }
            &__list
            {
                ul
                {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    li
                    {
                        line-height: 40px;
                        a
                        {
                            color: #fff;
                            transition: 0.2s;
                            &:hover
                            {
                                color: #bca21d;
                            }
                        }
                    }
                }
            }
        }
    }
    &__copyright 
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        border-top: 2px solid #bca21d;
        &__text
        {
            p
            {
                color: #fff;
                margin: 0;
                font-size: 12px;
                font-weight: 400;
            }
        }
        &__social
        {
            ul
            {
                display: flex;
                padding: 0;
                margin: 0;
                list-style: none;
                li
                {
                    margin-left: 20px;
                    &:first-child
                    {
                        margin-left: 0;
                    }
                    a
                    {
                        color: #fff;
                    }
                }
            }
        }
    }
}
@media (max-width: 576px)
{
    .footer
    {
        &__item
        {
            &__menu
            {
                margin-bottom: 30px;
            }
        }
    }
}