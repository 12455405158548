.slider
{
    background-image: url('../img/hero-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 10px solid #bca21d;
    max-height: 476px;
    &__item
    {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 680px;
        margin: auto;
        padding: 50px 0;
        &__title
        {
            h4
            {

                color: #fff;
                white-space: nowrap;
                text-align: center;
                line-height: 40px;
                letter-spacing: 1px;
                font-weight: 400;
                font-size: 40px;
            
            }
            ul
            {
                list-style: none;
                padding: 0;
                margin: 0;
                position: relative;
                height: 100px;

                li
                {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    transform: scale(1.5);
                    visibility: hidden;
                    opacity: 0;
                    transition: 0.4s;
                    &.active
                    {
                        transform: scale(1);
                        visibility: visible;
                        opacity: 1;
                    }
                    h3
                    {
                        color: #bca21d;
                        text-decoration: none;
                        position: relative;
                        white-space: nowrap;
                        text-align: center;
                        line-height: 90px;
                        letter-spacing: 0px;
                        font-weight: 200;
                        font-size: 60px;
                        &:after
                        {
                            position: absolute;
                            width: 100%;
                            height: 3px;
                            bottom: 5px;
                            left: 0;
                            background-color: rgb(188, 162, 29);
                            content: " ";
                        }
        
                    }
                }
            }
        }
        &__content
        {
            p
            {
                color: #fff;
                font-size: 18px;
                font-weight: 400;
            }
        }
        &__button
        {
            margin: 30px 0;
            a
            {
                font-size: 35px;
                color: #fff;
                font-weight: 600;
            }
        }
    } 
}
@media (max-width:768px)
{
    .slider
    {
        &__item
        {
            width: unset;
        }
    }
}