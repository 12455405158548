.services
{
    position: relative;
    padding: 70px 0;
    .row
    {
        .col-lg-3
        {
            position: sticky;
            top: 150px;
        }
    }
    &__title
    {
        h1
        {
            color: #bca21d;
            font-weight: 300;
            line-height: 50px;
            font-size: 33px;
            position: relative;
            &:after
            {
                position: absolute;
                width: 10%;
                height: 2px;
                bottom: -20px;
                left: 0;
                background-color: rgb(188, 162, 29);
                content: " ";
            }
        }

    }
    &__item
    {
        &__list
        {
            border-bottom: 1px solid #8e9c9c;
            padding: 50px;
            &:last-child
            {
                border-bottom: none;
            }
            &__image
            {
                img
                {
                    width: 100%;
                    height: auto;
                }
            }
            &__text
            {

                &__title
                {
                    h2
                    {
                        color: #59162f ;
                        font-weight: 300;
                    }
                }
                &__content
                {
                    p
                    {
                        color: #4f524b;
                        margin: 35px 0;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
                &__button
                {
                    a
                    {  
                        display: inline-block;
                        line-height: 45px;
                        background: #bca21d;
                        border: 1px solid #bca21d;
                        border-radius: 30px;
                        font-size: 14px;
                        padding: 0 37.7px;
                        font-weight: 400;
                        color: #fff;
                        text-decoration: none;
                        transition: 0.3s;
                        &:hover
                        {
                            
                            background: #fff;
                            color: #bca21d;
                        }
                    }

                }
            }

        }
    }
    &__footer
    {
        h5
        {
            font-size: 28px;
            color: #252622;
            font-weight: 100;
            line-height: 40px;
            margin: 30px 0;
        }
    }
}

@media (max-width : 991px)
{
    .services
    {
        &__item
        {
            &__list
            {
                text-align: center;
                &__image
                {
                    margin: 0 auto;
                }
                &__text
                {
                    &__button 
                    {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
@media (max-width : 767px)
{
    .services
    {
        &__title
        {
            h1
            {
                font-size: 25px;
            }
        }
    }
}