@font-face {
    font-family: sans;
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/sans/eot/IRANSansWeb_Bold.eot");
    src: url("../fonts/sans/eot/IRANSansWeb_Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/sans/woff2/IRANSansWeb_Bold.woff2") format("woff2"), url("../fonts/sans/woff/IRANSansWeb_Bold.woff") format("woff"), url("../fonts/sans/ttf/IRANSansWeb_Bold.ttf") format("truetype"); }
  
  @font-face {
    font-family: sans;
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/sans/eot/IRANSansWeb_Medium.eot");
    src: url("../fonts/sans/eot/IRANSansWeb_Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/sans/woff2/IRANSansWeb_Medium.woff2") format("woff2"), url("../fonts/sans/woff/IRANSansWeb_Medium.woff") format("woff"), url("../fonts/sans/ttf/IRANSansWeb_Medium.ttf") format("truetype"); }
  
  @font-face {
    font-family: sans;
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/sans/eot/IRANSansWeb_Light.eot");
    src: url("../fonts/sans/eot/IRANSansWeb_Light.eot?#iefix") format("embedded-opentype"), url("../fonts/sans/woff2/IRANSansWeb_Light.woff2") format("woff2"), url("../fonts/sans/woff/IRANSansWeb_Light.woff") format("woff"), url("../fonts/sans/ttf/IRANSansWeb_Light.ttf") format("truetype"); }
  
  @font-face {
    font-family: sans;
    font-style: normal;
    font-weight: 200;
    src: url("../fonts/sans/eot/IRANSansWeb_UltraLight.eot");
    src: url("../fonts/sans/eot/IRANSansWeb_UltraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/sans/woff2/IRANSansWeb_UltraLight.woff2") format("woff2"), url("../fonts/sans/woff/IRANSansWeb_UltraLight.woff") format("woff"), url("../fonts/sans/ttf/IRANSansWeb_UltraLight.ttf") format("truetype"); }
  
  @font-face {
    font-family: sans;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/sans/eot/IRANSansWeb.eot");
    src: url("../fonts/sans/eot/IRANSansWeb.eot?#iefix") format("embedded-opentype"), url("../fonts/sans/woff2/IRANSansWeb.woff2") format("woff2"), url("../fonts/sans/woff/IRANSansWeb.woff") format("woff"), url("../fonts/sans/ttf/IRANSansWeb.ttf") format("truetype"); }
  
  @font-face {
    font-family: sans_number;
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/IRANSansWeb.eot");
    src: url("../fonts/IRANSansWeb.eot?#iefix") format("embedded-opentype"), url("../fonts/IRANSansWeb.woff2") format("woff2"), url("../fonts/IRANSansWeb.woff") format("woff"), url("../fonts/IRANSansWeb.ttf") format("truetype"); }
  
  @font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/dana/eot/dana-light.eot");
    src: url("../fonts/dana/eot/dana-light.eot?#iefix") format("embedded-opentype"), url("../fonts/dana/woff2/dana-light.woff2") format("woff2"), url("../fonts/dana/woff/dana-light.woff") format("woff"); }
  
  @font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/dana/eot/dana-regular.eot");
    src: url("../fonts/dana/eot/dana-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/dana/woff2/dana-regular.woff2") format("woff2"), url("../fonts/dana/woff/dana-regular.woff") format("woff"); }
  
  @font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/dana/eot/dana-medium.eot");
    src: url("../fonts/dana/eot/dana-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/dana/woff2/dana-medium.woff2") format("woff2"), url("../fonts/dana/woff/dana-medium.woff") format("woff"); }
  
  @font-face {
    font-family: dana;
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/dana/eot/dana-bold.eot");
    src: url("../fonts/dana/eot/dana-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/dana/woff2/dana-bold.woff2") format("woff2"), url("../fonts/dana/woff/dana-bold.woff") format("woff"); }
  
  @font-face {
    font-family: dana;
    font-style: italic;
    font-weight: 500;
    src: url("../fonts/dana/eot/dana-mediumitalic.eot");
    src: url("../fonts/dana/eot/dana-mediumitalic.eot?#iefix") format("embedded-opentype"), url("../fonts/dana/woff2/dana-mediumitalic.woff2") format("woff2"), url("../fonts/dana/woff/dana-mediumitalic.woff") format("woff"); }
  
  @font-face {
    font-family: dana;
    font-style: italic;
    font-weight: 600;
    src: url("../fonts/dana/eot/dana-bolditalic.eot");
    src: url("../fonts/dana/eot/dana-bolditalic.eot?#iefix") format("embedded-opentype"), url("../fonts/dana/woff2/dana-bolditalic.woff2") format("woff2"), url("../fonts/dana/woff/dana-bolditalic.woff") format("woff"); }
  
  @font-face {

    font-family: 'ali';
    src: url("../fonts/ali/ali.eot?ihlxo2");
    src: url("../fonts/ali/ali.eot?ihlxo2#iefix") format("embedded-opentype"), url("../fonts/ali/ali.ttf?ihlxo2") format("truetype"), url("../fonts/ali/ali.woff?ihlxo2") format("woff"), url("../fonts/ali/ali.svg?ihlxo2#ali") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block; 
  }
  @font-face {
    font-family: yekan;
    font-style: normal;
    font-weight: bold;
    src: url("../fonts/yekan/eot/iranyekanwebbold(fanum).eot");
    src: url("../fonts/yekan/eot/iranyekanwebbold(fanum).eot?#iefix") format("embedded-opentype"), url("../fonts/yekan/woff2/iranyekanwebbold(fanum).woff2") format("woff2"), url("../fonts/yekan/woff/iranyekanwebbold(fanum).woff") format("woff"), url("../fonts/yekan/ttf/iranyekanwebbold(fanum).ttf") format("truetype"); }
  
  @font-face {
    font-family: yekan;
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/yekan/eot/iranyekanweblight(fanum).eot");
    src: url("../fonts/yekan/eot/iranyekanweblight(fanum).eot?#iefix") format("embedded-opentype"), url("../fonts/yekan/woff2/iranyekanweblight(fanum).woff2") format("woff2"), url("../fonts/yekan/woff/iranyekanweblight(fanum).woff") format("woff"), url("../fonts/yekan/ttf/iranyekanweblight(fanum).ttf") format("truetype"); }
  
  @font-face {
    font-family: yekan;
    font-style: normal;
    font-weight: normal;
    src: url("../fonts/yekan/eot/iranyekanwebregular(fanum).eot");
    src: url("../fonts/yekan/eot/iranyekanwebregular(fanum).eot?#iefix") format("embedded-opentype"), url("../fonts/yekan/woff2/iranyekanwebregular(fanum).woff2") format("woff2"), url("../fonts/yekan/woff/iranyekanwebregular(fanum).woff") format("woff"), url("../fonts/yekan/ttf/iranyekanwebregular(fanum).ttf") format("truetype"); 
  }
  @font-face {
    font-family: yekan;
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/yekan/eot/iranyekanweblight(fanum).eot');
    src: url('../fonts/yekan/eot/iranyekanweblight(fanum).eot') format('embedded-opentype'),
        /* IE6-8 */
        url('../fonts/yekan/ttf/iranyekanweblight(fanum).ttf') format('ttf'),
  
        url('../fonts/yekan/woff/iranyekanweblight(fanum).woff') format('woff'),
        /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('../fonts/yekan/woff2/iranyekanweblight(fanum).woff2') format('woff2');
  }
  @font-face {
    font-family: yekan;
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/yekan/eot/iranyekanwebregular(fanum).eot');
    src: url('../fonts/yekan/eot/iranyekanwebregular(fanum).eot') format('embedded-opentype'),
        /* IE6-8 */
        url('../fonts/yekan/ttf/iranyekanwebregular(fanum).ttf'),
  
        url('../fonts/yekan/woff/iranyekanwebregular(fanum).woff') format('woff'),
        /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('../fonts/yekan/woff2/iranyekanwebregular(fanum).woff2') format('woff2');
  }
  @font-face {
    font-family: yekan;
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/yakan/eot/iranyekanwebbold(fanum).eot');
    src: url('../fonts/yakan/eot/iranyekanwebbold(fanum).eot') format('embedded-opentype'),
        /* IE6-8 */
        url('../fonts/yakan/ttf/iranyekanwebbold(fanum).ttf') format('ttf'),
        /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
        url('../fonts/yekan/woff/iranyekanwebbold(fanum).woff') format('woff'),
        url('../fonts/yekan/woff2/iranyekanwebbold(fanum).woff2') format('woff2');
  
  }